import React from 'react';
import { useSelector } from 'react-redux';
import './style/Style.css';

const Loader = () => {
    const isLoading = useSelector((state) => state.loading.isLoading);

    if (!isLoading) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
